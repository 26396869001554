import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import { AuthService } from '../../auth/services/auth.service';
import { TrackingSuiteService, TrackingRecord } from '@siemens/tracking-suite-angular-lib';
import { IUserData, UserService } from './user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  previousPath;
  user: any;
  userData: IUserData;
  guid: string; // hashed from GID
  visitToken: string; // hashed from accessToken
  engTranslations;
  rawAccessToken: string;
  subs: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private readonly trackingSuiteService: TrackingSuiteService,
    private authService: AuthService,
    private router: Router
  ) {
    window['ste_statistic'] = window['ste_statistic'] || [];
    this.authService.getAuthInfo().subscribe(({ accessToken, idToken }) => {
      this.userData = this.userService.idTokenToUserData(idToken);
      this.guid = Md5.hashStr(this.userData.gid);
      this.visitToken = Md5.hashStr(accessToken);
      this.rawAccessToken = accessToken;
    });
    this.loadEnglishTranslsations();
  }

  private loadEnglishTranslsations(): void {
    this.subs.push(this.translate.getTranslation('en').subscribe(value => {
      if (value) {
        this.engTranslations = value;
      }
    }));
  }

  async addWCSTrackingItem(itemType: 'route' | 'action' | 'language', item: string) {
    if (!this.user) {
      this.user = this.authService.user;
    }

    const newTrackingRecord = this.setUpNewRecordFormat(itemType, item);
    this.useNewTrackingService(newTrackingRecord);
  }

  private setUpNewRecordFormat(itemType: 'route' | 'action' | 'language', item: string): TrackingRecord {
    let action: 'click' | 'navigate' = 'click';
    if (itemType === 'route') {
      action = 'navigate';
    } else {
      if (this.router.url.includes('work-simulations')) {
        item = itemType + ' - ' + 'work-sim: ' + item;
      } else {
        item = itemType + ' - ' + item;
      }
    }
    return {
      accessToken: this.rawAccessToken,
      userGid: this.guid,
      actionType: action,
      actionValue: item,
      orgCode: this.userData && this.userData.orgCode ? this.userData.orgCode : 'unset',
      language: this.translate.currentLang ?? 'en',
      userCountry: this.userData && this.userData.country ? this.userData.country : 'unset',
    }
  }

  private useNewTrackingService(record: TrackingRecord): void {
    this.trackingSuiteService.send(record).subscribe();
  }
}
