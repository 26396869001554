import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderComponent } from './static/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthGmsAngularClientModule } from './auth/auth.module';
import { httpInterceptorProviders } from './shared/http-interceptors';
import { FooterComponent } from './static/footer/footer.component';
import { MatTableModule } from '@angular/material/table';
import { filter, tap, Observable } from 'rxjs';
import { AuthService } from './auth/services/auth.service';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { TRACKING_CONFIG, TrackingSuiteModule } from '@siemens/tracking-suite-angular-lib';
import { environment } from 'src/environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatTableModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AuthGmsAngularClientModule,
    TrackingSuiteModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: TRACKING_CONFIG,
      useValue: {
        apiKey: environment.newTracking.apiKey,
        //  apiVersion: 'v2', // optional, default = 'v2'
        endpointStage: environment.newTracking.endpointStage
      }
    },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent],
  exports: [FooterComponent]
})
export class AppModule implements DoBootstrap {
  constructor(private authService: AuthService) {}

  public ngDoBootstrap(appRef: ApplicationRef): void {
    this.authService.isAuthenticated$
      .pipe(
        filter(auth => !!auth),
        tap(() => appRef.bootstrap(AppComponent))
      )
      .subscribe();
  }
}

function initializeAppFactory(authService: AuthService): () => Observable<boolean> {
  return () =>
    authService.isAuthenticated$.pipe(
      filter(auth => !auth),
      tap(() => authService.login())
    );
}
