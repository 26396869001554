import { Component } from '@angular/core';

@Component({
  selector: 'shig-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor() {
  }

  public currentYear = (new Date()).getFullYear();


  openLink(link) {
    window.open(link, '_blank');
  }

}
