<div class="lay-wrapper lay-wrapper--content lay-wrapper--default">

  <div class="footer-links">
    <div style="flex: 1;"></div>
    <p (click)="openLink('https://www.siemens.com')">siemens.com</p>
    <p>© Siemens AG AG, {{currentYear}}</p>
    <p (click)="openLink('https://new.siemens.com/global/en/general/legal.html')">Corporate Information</p>
    <p (click)="openLink('https://new.siemens.com/global/en/general/privacy-notice.html')">Privacy Policy</p>
    <p (click)="openLink('https://new.siemens.com/global/en/general/cookie-notice.html')">Cookie Policy</p>
    <p (click)="openLink('https://new.siemens.com/global/en/general/terms-of-use.html')">Terms of use</p>
    <p (click)="openLink('https://www.siemens.com/global/en/general/digital-id.html')">Digital ID</p>
    <div style="flex: 1;"></div>
  </div>
</div>

