import { TrackingService } from './shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { skip , tap } from 'rxjs/operators';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'shig-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sag-interview-generator';
  previousPath;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private tracking: TrackingService,
    private authService: AuthService
  ) {
    translate.setDefaultLang('en');
    const lang = localStorage.getItem('language');
    if (lang !== null && lang !== 'null') {
      translate.use(lang);
      // this.userData.setCurrentLanguage(lang);
    } else { translate.use('en'); }

    this.router.events.subscribe(res => {
      window.scrollTo(0, 0);
      if (res instanceof NavigationStart && !res.url.startsWith('/auth')) {
        void this.tracking.addWCSTrackingItem('route', res.url);
      }
    });    

    this.translate.onLangChange
      .pipe(
        skip(1),
        tap(() => void this.tracking.addWCSTrackingItem('language', 'Language Changed'))
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.routeToRedirect();
    void this.authService.loadUser();
  }

  private routeToRedirect(): boolean {
    const url = window.localStorage.getItem('redirectUrl');
    if (url) {
      void this.router.navigateByUrl(url);
      window.localStorage.removeItem('redirectUrl');
    }
    return !!url;
  }

}
