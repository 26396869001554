import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AuthService, ID_TOKEN_KEY } from '../auth/services/auth.service';

@Injectable()
export class TokenInjector implements HttpInterceptor {
  private readonly tokenBlacklist = ['https://dev.authproxy.siemens.cloud/', 'https://prod.authproxy.siemens.cloud/'];

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.urlInTokenBlacklist(request.url) && !request.url.includes("https://interview") ) {
      return this.auth.getIdToken().pipe(
        switchMap((token) => {
          if (token) {
            localStorage.setItem(ID_TOKEN_KEY, token);
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
              },
            });
          }
          return next.handle(request);
        })
      );
    }

    return next.handle(request);
  }

  private urlInTokenBlacklist(url: string): boolean {
    return this.tokenBlacklist.some(whiteUrl => url.includes(whiteUrl));
  }
}
