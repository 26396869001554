// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authentication: {
    authWellknownEndpointUrl: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg/.well-known/openid-configuration',
    authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg',
    unauthorizedRoute: '/unauthorized',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: '53eag1pkkd8ske2ad2nviud8ge', // to work with DEV BE
    scope: 'openid profile email',
    responseType: 'code',
    silentRenew: true,
    autoUserInfo: true,
    useRefreshToken: true,
    logLevel: 2,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    renewUserInfoAfterTokenRenew: true,
    // ignoreNonceAfterRefresh: true
    // secret: '6c6a00880f314fce871580effd7c630f',
    // tokens: 'id_token token',
    // scheme: 'saml2',
    // provider: 'siemens',
    // clientId: '95d4b7b1788d424ea3576c121b9fc401',
    // appID: '2db8d85ae4624f4cbc91b98a7a5cdf50',
    // baseUrl: 'https://auth-gms-app.siemens.cloud/2db8d85ae4624f4cbc91b98a7a5cdf50',
    // callbackUrl: 'https://dev.global-interview-toolkit.siemens.cloud/auth/callback'
  },
  backendEndpoint: 'https://api.dev.global-interview-toolkit.siemens.cloud',
  newTracking: {
    endpointStage: 'dev',
    apiKey: 'FtcFmyrAos1Z0mi14SKpy17yvVo5YL22gFrEBdJg',
  },
  //tracking: false, //Not used anymore, see ITSA-36
  //trackingEndpoint: 'https://tkgycwrfbg.execute-api.eu-central-1.amazonaws.com/prod/gmstrackings/gms-tracking', //Not used anymore, see ITSA-36
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
