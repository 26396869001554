<div class="mod-header">
  <div class="mod-logo">
    <!-- <div class="m-logo m-logo--myshare js-logo js-logo-myshare">
      <a (click)="gotoStartPage()"></a>
    </div> -->
    <div class="m-logo m-logo--siemens js-logo js-logo-siemens" >
      <a (click)="gotoStartPage()"></a>
    </div>
  </div>


    <div class="mod-navigation mod-navigation--icons">



      <div class="m-icon m-icon--menu-items" style="position: relative;" (click)="openLanguageSelection()" >
        <!-- <i class="material-icons-outlined">language</i> -->
        <div class="m-icon-label" style="color: #009999"><span class="material-icons">language</span></div>
        <div class="mat-elevation-z4 language-dropdown" [ngClass]="{'show-lang-sel': showLanguageSelection}" >
          <ul>
            <li class="lang-item" (click)="changeLanguage('en')"><p  style="margin: 0;"><i class="material-icons" style="vertical-align: bottom;" [ngStyle]="{'color': languageSelected === 'en' ? 'black' : 'transparent'}">done</i> EN</p></li>
            <li class="lang-item" (click)="changeLanguage('de')"><p  style="margin: 0;"><i class="material-icons" style="vertical-align: bottom;" [ngStyle]="{'color': languageSelected === 'de' ? 'black' : 'transparent'}">done</i> DE</p></li>
            <li class="lang-item" (click)="changeLanguage('fr')"><p  style="margin: 0;"><i class="material-icons" style="vertical-align: bottom;" [ngStyle]="{'color': languageSelected === 'fr' ? 'black' : 'transparent'}">done</i> FR</p></li>
            <li class="lang-item" (click)="changeLanguage('es')"><p  style="margin: 0;"><i class="material-icons" style="vertical-align: bottom;" [ngStyle]="{'color': languageSelected === 'es' ? 'black' : 'transparent'}">done</i> ES</p></li>
            <li class="lang-item" (click)="changeLanguage('zh')"><p  style="margin: 0;"><i class="material-icons" style="vertical-align: bottom;" [ngStyle]="{'color': languageSelected === 'zh' ? 'black' : 'transparent'}">done</i> ZH</p></li>
<!--            <li class="lang-item" (click)="changeLanguage('cfr')"><p  style="margin: 0;"><i class="material-icons" style="vertical-align: bottom;" [ngStyle]="{'color': languageSelected === 'cfr' ? 'black' : 'transparent'}">done</i> FR (Canadian)</p></li> &ndash;&gt;-->
            <!-- <li class="lang-item" (click)="changeLanguage('cfr')"><i class="material-icons" style="vertical-align: bottom;" [ngStyle]="{'color': languageSelected === 'cfr' ? 'black' : 'transparent'}">done</i> FR (Canadian)</li> -->
            <!-- <li class="lang-item"><i class="material-icons" style="vertical-align: bottom;" [ngStyle]="{'color': languageSelected === 'de' ? 'black' : 'transparent'}">done</i> DE</li> -->
          </ul>
        </div>
        <p class="selected-language">{{languageSelected.toUpperCase()}}</p>
      </div>
      <!-- <div class="m-icon m-icon--main" (click)="openMenu('Menu')">
        <svg width="22px" height="19px" viewBox="0 0 22 19" version="1.1">
          <g id="768-1023px-Tablet_Large,-ca.-128,5%-scale-Copy-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(-724.000000, -23.000000)">
            <g>
              <g transform="translate(723.000000, 19.500000)">
                <polygon id="Fill-95" fill="#ffffff" points="1.75384615 8.48615385 22.4307692 8.48615385 22.4307692 4.05538462 1.75384615 4.05538462"></polygon>
                <polygon id="Fill-96" fill="#ffffff" points="1.75384615 21.7784615 22.4307692 21.7784615 22.4307692 17.3476923 1.75384615 17.3476923"></polygon>
                <polygon id="Fill-97" fill="#ffffff" points="1.75384615 15.3169231 22.4307692 15.3169231 22.4307692 10.8861538 1.75384615 10.8861538"></polygon>
              </g>
            </g>
          </g>
        </svg>
      </div> -->
      <!-- <menu></menu> -->
    </div>
  </div>

