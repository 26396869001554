import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'work-simulations',
    loadChildren: () => import('./work-simulations/work-simulations.module').then(m => m.WorkSimulationsModule),
    canActivate: [AutoLoginPartialRoutesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
