import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';

export interface IUserData {
  aud: string;
  company?: string;
  country?: string;
  exp: number;
  given_name: string;
  iat: number;
  iss: string;
  family_name: string;
  email: string;
  nickname: string
  nonce: string;
  orgCode: string;
  gid: string;
}

@Injectable({
  providedIn: 'root'
})


export class UserService {
  constructor(private authService: AuthService) { 
  }

  async getIDTokenUserData(): Promise<IUserData> {
    const idToken = await this.authService.getIdToken().toPromise();
    return this.idTokenToUserData(idToken);
  }

  idTokenToUserData(idToken): IUserData {
    const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
    const data: IUserData = {
      aud: decodedToken.aud || '',
      company: decodedToken["custom:company"],
      country: decodedToken["custom:country"],
      exp: decodedToken.exp,
      given_name: decodedToken["given_name"] || '',
      iat: decodedToken.iat,
      iss: decodedToken.iss,
      family_name: decodedToken['family_name'] || '',
      email: decodedToken.email || '',
      nickname: decodedToken.nickname || '',
      nonce: decodedToken.nonce,
      orgCode: decodedToken['custom:org_code'],
      gid: decodedToken['custom:gid']
    };
    return data;
  }

}
