import { Injectable, InjectionToken } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthGmsClientConfig } from '../models/auth-gms-client.config';

export const AuthGmsClientConfigService = new InjectionToken<AuthGmsClientConfig>('AuthGmsClientConfig');

export const ID_TOKEN_KEY = 'id_token';
export const ACCESS_TOKEN_KEY = 'access_token';
export const EXP_HINT = 'expires_at';
export const NONCE_KEY = 'nonce';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated$: Observable<boolean>;
  public authUserInit: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private oidcSecurityService: OidcSecurityService) {
    this.isAuthenticated$ = this.oidcSecurityService
      .checkAuth().pipe(
        map(auth => auth.isAuthenticated)
      );
  }

  public login(): void {
    const url = window.location.pathname + window.location.search;
    window.localStorage.setItem('redirectUrl', url);
    this.oidcSecurityService.authorize();
  }

  public logOff(): void {
    this.oidcSecurityService.logoff();
  }

  public getIdToken(): Observable<string> {
    return this.oidcSecurityService.getIdToken();
  }

  public getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  public getAuthInfo(): Observable<{ isAuthenticated: boolean, userData, accessToken: string, idToken: string }>{
    return this.oidcSecurityService.checkAuth();
  }

  get user() {
    const user = localStorage.getItem(ID_TOKEN_KEY);
    if (user) {
      return AuthService.idTokenToUserData(user);
    }
    return null;
  }

  private static idTokenToUserData(idToken: string) {
    const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
    const data = {
      aud: decodedToken.aud || '',
      company: decodedToken['custom:company'],
      country: decodedToken['custom:country'],
      exp: decodedToken.exp,
      firstName: decodedToken.given_name || '',
      iat: decodedToken.iat,
      iss: decodedToken.iss,
      lastName: decodedToken.family_name || '',
      email: decodedToken.email || '',
      nickname: decodedToken.nickname || '',
      nonce: decodedToken.nonce,
      orgCode: decodedToken['custom:org_code'],
      sub: decodedToken['custom:gid']
    };
    return data;
  }

  public async loadUser(): Promise<void> {
    const accessToken = await this.getAccessToken().toPromise();
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }
}
