import { Component, ElementRef, HostListener, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'shig-sf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent {

  @ViewChild('navigationItem') navigationItem: ElementRef;

  public languageSelected = 'en';

  showLanguageSelection = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showLanguageSelection = false;
    }
  }

  constructor(
    private eRef: ElementRef,
    private router: Router,
    private renderer: Renderer2,
    private translate: TranslateService,
  ) {
    const currLang = localStorage.getItem('language');
    if (currLang && currLang !== this.languageSelected) {
      this.changeLanguage(currLang);
    }
    this.translate.onLangChange.subscribe(() => {
      localStorage.setItem('language', this.languageSelected);
    });
  }

  openLanguageSelection() {
    this.showLanguageSelection = !this.showLanguageSelection;
  }

  public gotoStartPage() {
    this.router.navigate(['/']);
  }


  changeLanguage(e) {
    this.languageSelected = e;
    this.translate.use(this.languageSelected);
  }

}
